import React from 'react';
import { constants } from '../../helper/constant';
import PhoneEmailVerification from '../phoneEmailVerification/phoneEmailVerification';

const useModal = () => {

    let [modal, setModal] = React.useState(false);
    let [modalContent, setModalContent] = React.useState('I am the Modal Content');
    let [modalHeading, setModalHeading] = React.useState('Modal heading');
    let [user, setUser] = React.useState({});
    let [isResetFlow, setIsResetFlow] = React.useState(false);

    let handleModal = (visible) => {
        setModal(visible);
    };

    let deepCompare = (arg1, arg2) => {
        if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
            if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]') {
                if (Object.keys(arg1).length !== Object.keys(arg2).length) {
                    return false;
                }
                return (Object.keys(arg1).every(function (key) {
                    return deepCompare(arg1[key], arg2[key]);
                }));
            }
            return (arg1 === arg2);
        }
        return false;
    }

    let updateUser = (userObj, mergeFlag = true, visible = true, isResetFlow = false, parentFlow = 'signReset') => {

        const updatedUser = mergeFlag ? { ...user, ...userObj } : userObj;
        if (userObj !== undefined && userObj !== null && deepCompare(user, updatedUser) === false) {
            if (updatedUser.changed === undefined || (user.changed === undefined && updatedUser.changed === null)) {
                updatedUser.changed = true;
            }
            updatedUser.parentFlow = parentFlow;
            setUser(updatedUser);
        }

        if (isResetFlow !== undefined && isResetFlow !== null && (isResetFlow === true || isResetFlow === false)) {
            setIsResetFlow(isResetFlow);
        } else {
            isResetFlow = false;
            setIsResetFlow(isResetFlow);
        }

        if (updatedUser.status === constants.Account.Status.NeedsVerification || updatedUser.status === constants.Account.Status.NeedsVerificationGuest) {
            setModalHeading('OTP Verification');
            setModalContent(<PhoneEmailVerification parentFlow={parentFlow} user={updatedUser} disablePhoneOTP={false} updateUserCallback={updateUser} isResetFlow={isResetFlow ? 'true' : 'false'} />);
        } else {
            visible = false;
        }

        if (visible === true || visible === false) {
            handleModal(visible);
        }

    };

    return { user, updateUser, modal, modalContent, modalHeading, handleModal, isResetFlow, setIsResetFlow };
}

export default useModal;