import React, {useState, useEffect, useRef} from 'react';

import './accordian.css';
import downicon from './down-chevron.svg';

const useHeight = () => {
    const [height, setHeight] = useState(0);
    const elRef = useRef();

    useEffect(() => {
        window.addEventListener('resize', getHeight);
        getHeight();

        return () => {
            window.removeEventListener('resize', getHeight)
        }
    }, []);

    function getHeight() {
        setHeight(elRef.current.offsetHeight);
    }

    return {height, elRef};
}

const Accordian = ({children}) => {
    const [active, setActive] = useState(0);

    const onToggle = (val) => {
        if(active !== val) {
            setActive(val)
        } else {
            setActive(-1)
        }
    }

    return(
        <div className="accordian">
            {children.map((child, index) => {
                child.props = {...child.props, index, active, onToggle}
                return child;
            })}
        </div>
    )
}

const AccordianItem = ({children, onToggle, index, active}) => {
    return(
        <div className="accordian-item">
            {children.map((child) => {
                child.props = {...child.props, index, active, onToggle}
                return child
            })}
        </div>
    )
}

const AccordianHead = ({title, icon, description, index, active, onToggle}) => {
    return(
        <div className="accordian-head" onClick={(e) =>{
            if(e.target.className === 'accordian-info-icon' || e.target.className === 'accordian-info-text') return;
            onToggle(index);
        }}>
            <h4>{title}</h4>
            <p className="accordian-info">
                <span className="accordian-info-icon"></span>
                <span className="accordian-info-text">
                    {description}
                </span>
            </p>
            <span class={`accordian-icon ${index === active ? 'active' : '' }`}>
                <img src={downicon}/>
            </span>
        </div>
    )
}

const AccordianBody = ({children, index, active}) => {
    const {height, elRef} = useHeight();
    return(
        <div className="accordian-body" style={{height: index === active ? height : 0}}>
            <div className="accordian-body-wrapper" ref={elRef}>
                {children}
            </div>
        </div>
    )
}

export {Accordian, AccordianItem, AccordianHead, AccordianBody};