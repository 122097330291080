import './signin.css';

import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { motion } from "framer-motion";

import { constants } from '../../../helper/constant';
import { ModalContext } from './../auth-modal/auth-modalContext';
import { signInOrReset } from './../../../services/api.service';
import { Toast } from './../../alert/toast';

class SignIn extends React.Component {

  static contextType = ModalContext;
  constructor(props) {
    super(props);
    this.showReset = true;
    if (props['show-reset-password'] && props['show-reset-password'].toLowerCase() === "false") {
      this.showReset = false;
    }

    this.state = {
      validated: false,
      email: '',
      password: '',
      isResetFlow: false,
      toasts: {
        invalidEmail: false,
        resetSuccess: false
      },
      loading: false
    };

    this.passwordRef = createRef();

    this.handleChange = this.handleChange.bind(this);
    this.signIn = this.signIn.bind(this);
    this.handleUserUpdate = this.handleUserUpdate.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.showResetMessage = this.showResetMessage.bind(this);
    this.switchFlow = this.switchFlow.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  switchFlow(value) {
    this.setState({ validated: false, email: '', password: '', isResetFlow: value, toasts: { invalidEmail: false, resetSuccess: false } });
  }

  async signIn(event) {
    event.preventDefault();

    const node = ReactDOM.findDOMNode(this);
    const form = node.querySelector('form');

    //validate form
    let formValidationResult = form.checkValidity();
    this.setState({
      validated: true
    });

    if (formValidationResult == false) {
      if (event) {
        event.stopPropagation();
      }
      return false;
    }

    const data = {
      email: this.state.email,
      password: this.state.password
    };

    this.setState((state, props) => {
      return { toasts: { ...state.toasts, invalidEmail: false, resetSuccess: false }, loading: true }
    });

    const response = await (await signInOrReset(data, this.state.isResetFlow)).json();

    this.setState({
      validated: false
    });

    if (response.status === 404) {
      this.setState((state, props) => {
        return { toasts: { ...state.toasts, invalidEmail: true }, loading: false }
      });
    } else {
      if (response && response.user) {
        this.setState((state, props) => {
          return { loading: false }
        });
        this.passwordRef.current.classList.remove('is-invalid');
        this.context.updateAccountUser({ ...data, ...response.user }, false, true, (this.state.isResetFlow ? constants.Account.Flow.ResetPassword : constants.Account.Flow.SignIn));
        this.handleUserUpdate(response.user, true);
      }
    }
  }

  handleUserUpdate(updateUser, ignoreParentFlow = false) {
    if (updateUser && updateUser.status === constants.Account.Status.Active) {
      if ((this.state.isResetFlow && ignoreParentFlow) || (this.state.isResetFlow && updateUser.parentFlow !== undefined && updateUser.parentFlow !== null && updateUser.parentFlow === constants.Account.Flow.ResetPassword)) {
        this.showResetMessage();
      } else if ((!this.state.isResetFlow && ignoreParentFlow) || (!this.state.isResetFlow && updateUser.parentFlow !== undefined && updateUser.parentFlow !== null && updateUser.parentFlow === constants.Account.Flow.SignIn)) {
        const redirectUrl = this.props['data-redirect'];
        if (redirectUrl && redirectUrl.trim() !== "") {
          window.location.href = global.ii_erw_serverbase + redirectUrl;
        }
        if (this.props.onSuccess) {
          this.props.onSuccess(updateUser);
        }
      }
    }
  }

  closeAlert(selector) {
    try { document.querySelector(selector).closest('.userMessage').style.display = 'none'; } catch (e) { }
  }

  showResetMessage() {
    if (this.state.toasts.resetSuccess == false) {
      this.setState((state, props) => { return { email: '', password: '', toasts: { ...state.toasts, resetSuccess: true } } });
    }
  }

  render() {
    const variants = {
      hidden: { y: 10, opacity: 0 },
      visible: { y: 0, opacity: 1 },
      exit: { y: -10, opacity: 0 },
    }

    return (
      <motion.div transition={{ stiffness: 0, duration: 0.6 }} variants={variants}>
        <ModalContext.Consumer>
          {modalState => {
            if (modalState.user && modalState.user.notify) {
              this.handleUserUpdate(modalState.user);
              this.context.updateAccountUser(modalState.user, false, true, (this.state.isResetFlow ? constants.Account.Flow.ResetPassword : constants.Account.Flow.SignIn));
            }
          }}
        </ModalContext.Consumer>

        <Form className="ideal-form loginform" noValidate validated={this.state.validated}>          

          <Form.Row className="full-width justify-content-center">
            <Form.Group as={Col} lg="6" md="8" sm="12"> 
              <Form.Label><h4 style={{ 'padding-bottom': '0px'}} className="info-title">{this.state.isResetFlow === false ? 'Sign In' : 'Reset Password'}</h4></Form.Label>
            </Form.Group>
          </Form.Row>

          {this.state.toasts.invalidEmail && (<Toast title="There was a problem" message="Invalid Email or Password." variant="warning" />)}

          {this.state.toasts.resetSuccess && this.state.isResetFlow && (<Toast title="Success" message="Password Reset Successfull. Please try 'Sign In' now." variant="success" />)}

          <Form.Row className="full-width justify-content-center">
            <Form.Group as={Col} lg="6" md="8" sm="12"> 
              {/* <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                
              </InputGroup> */}
              <Form.Label><span class="mand-span">*</span>Email ID</Form.Label>
              <Form.Control name="email" className="form-control" type="email" value={this.state.email} onChange={this.handleChange}
                  minlength="4" maxlength="50" required pattern="^[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9]+)?@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,5}$" />
                <Form.Control.Feedback type="invalid">Invalid email.</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row className="full-width justify-content-center">
            <Form.Group as={Col} lg="6" md="8" sm="12">
            <Form.Label><span class="mand-span">*</span>{this.state.isResetFlow === false ? 'Password' : 'New Password'}</Form.Label>
              <Form.Control autocomplete="off" ref={this.passwordRef} type="password" name="password" id="password" className="form-control"
                value={this.state.password} onChange={this.handleChange} required pattern="^(?=^.{8,20}$)(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[~!@#$%^*()_+`={}\[\]|\\:;',.?\/-]+)(?:[a-zA-Z0-9~!@#$%^*()_+`={}\[\]|\\:;',.?\/-]*)$" minlength="8" maxlength="20" />
              <Form.Control.Feedback type="invalid">Your password must be 8-20 characters long, contain letters (upper &amp; lowercase), numbers, and special characters.</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <div className="form-item" style={{ 'text-align': 'center' }}>
            <button disabled={this.state.loading} type="submit" id="ii-wc-login" className="form-button" onClick={this.signIn}>{this.state.loading ? 'wait...' : (this.state.isResetFlow === false ? 'Login' : 'Reset Password')}</button>
          </div>

          {this.showReset && <a style={{ 'text-align': 'center', 'cursor': 'pointer' }} className="form-toggle" onClick={() => { this.switchFlow(!this.state.isResetFlow) }}>{this.state.isResetFlow === false ? 'Forgot Password?' : 'Sign In?'} </a>}
        </Form>
      </motion.div>

    );
  }
}

export default SignIn;
