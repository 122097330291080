import React from 'react';
import ReactDOM from 'react-dom';
import { motion } from "framer-motion";

import { sendCustomEvent } from './../../helper/functions';
import SignIn from './signin/signin';
import SignUp from './signup/signup';
import { constants } from './../../helper/constant';

class Account extends React.Component {

    constructor(props) {
        super(props);        

        this.signInDataRedirect = "";
        if (props['sign-in-redirect-url']) {
            this.signInDataRedirect = props['sign-in-redirect-url'];
        }

        this.signUpDataRedirect = "";
        if (props['sign-up-redirect-url']) {
            this.signUpDataRedirect = props['sign-up-redirect-url'];
        }

        //Override redirect path, first preference is to query string "returnUrl"
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('returnUrl') !== null){
            this.signInDataRedirect = this.signUpDataRedirect = decodeURIComponent(urlParams.get('returnUrl'))
        }

        this.showGuestChecout = true;
        if (props['show-guest-checkout']) {
            this.showGuestChecout = props['show-guest-checkout'].toLowerCase() === "true";
        }

        this.state = {
            currentFlow: constants.Account.Flow.SignIn
        };
    }

    changeCurrentFlow(flowName) {
        this.setState({ currentFlow: flowName });
    }

    guestChecoutHandler() {
        //Subscriber Code: window.addEventListener(window.ii_consts.Account.GuestCheckoutClickedEventName,function(event){debugger})
        const node = ReactDOM.findDOMNode(this);
        try {
            sendCustomEvent(constants.Account.GuestCheckoutClickedEventName, null, { callerId: node.parentElement.getAttribute('id'), callerElement: node.parentElement });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const variants = {
            hidden: { y: 10, opacity: 0 },
            visible: { y: 0, opacity: 1 },
            exit: { y: -10, opacity: 0 },
        }

        return (
            <motion.div transition={{ stiffness: 0, duration: 0.6 }} variants={variants}>
                {
                    this.state.currentFlow === constants.Account.Flow.SignIn &&
                    <div>
                        <SignIn data-redirect={this.signInDataRedirect}></SignIn>
                        <div style={{ 'text-align': 'center', 'margin-top': '20px' }}>
                            <label>New Customer? </label> &nbsp;
                            <a style={{ 'cursor': 'pointer', 'display': 'inline-block', 'margin-top': '0px' }} className="form-toggle" onClick={() => { this.changeCurrentFlow(constants.Account.Flow.ResetPassword) }}> Create an account </a> &nbsp;
                            {this.showGuestChecout &&
                                <a style={{ 'cursor': 'pointer', 'display': 'inline-block', 'margin-top': '0px' }} className="form-toggle" onClick={() => { this.guestChecoutHandler() }}> Guest Checkout </a>
                            }
                        </div>
                    </div>
                }

                {
                    this.state.currentFlow === constants.Account.Flow.ResetPassword &&
                    <div className="container" style={{ 'position': 'relative','padding': '0px', 'margin': '0px', 'max-width': '100% !important' }}>
                        <a style={{ 'cursor': 'pointer', 'display': 'inline-block', 'float': 'right', 'position': 'absolute', 'right': '8px', 'z-index': '99' }} className="form-toggle signInLinkContainer" onClick={() => { this.changeCurrentFlow(constants.Account.Flow.SignIn) }}> Sign In </a>
                        <div><SignUp data-redirect={this.signUpDataRedirect} triggerSignIn={() => { this.changeCurrentFlow(constants.Account.Flow.SignIn) }}></SignUp></div>
                    </div>
                }
            </motion.div>

        );
    }
}

export default Account;