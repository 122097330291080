import './signup.css';
import React from 'react';
import ReactDOM from 'react-dom';
import InputMask from 'react-input-mask';
import { Form, Col, Button } from 'react-bootstrap';

import { constants } from './../../../helper/constant';
import { formatPhoneNumber, postDLEvent, sendCustomEvent } from './../../../helper/functions';
import { ModalComponent } from './../../modal/modal';
import { ModalContext } from './../auth-modal/auth-modalContext';
import { custInfoSubmit, custInfoTermsCondition, addProduct, checkCart } from './../../../services/api.service';
import Loader from './../../loader';

class SignUp extends React.Component {

  static contextType = ModalContext;
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);

    this.submitBtnText = 'Create Account';
    if (this.props['submitBtnText']) {
      this.submitBtnText = this.props['submitBtnText'];
    }

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      zip: '',
      password: '',
      confirmPassword: '',
      wantsPromotionText: false,
      wantsPromotionEmail: urlParams.get('ca') === 'true' ? false : true,
      wantsAppointmentText: urlParams.get('ca') === 'true' ? false : true,
      agreeTerms: false,
      validated: false,
      showTermsConditionModal: false,
      showCustomerActionRequiredModal: false,
      showInvalidPasswordModal: false,
      loader: false
    };

    this.allowRedirect = true;
    this.termsConditionsText = '';

    this.handleChange = this.handleChange.bind(this);
    this.paynow = this.paynow.bind(this);
    this.handleUserUpdate = this.handleUserUpdate.bind(this);
    this.termsConditionsClick = this.termsConditionsClick.bind(this);
    this.hideTermsConditionModal = this.hideTermsConditionModal.bind(this);
    this.checkCart = this.checkCart.bind(this);
    this.setTermsConditionsText = this.setTermsConditionsText.bind(this);
    this.zipKeyUp = this.zipKeyUp.bind(this);
  }

  componentDidMount() {
    try {
      this.checkCart();
      // sendCustomEvent('CustInfoComponentLoad', this.state, { component: ReactDOM.findDOMNode(this) });
      // sendCustomEvent('StoreStep', { "step": "customer-information" });
      // postDLEvent('checkout-customer-information');
    } catch (error) { }
  }

  async checkCart() {
    const response = await (await checkCart()).json();
    if (response && response.isLTGInCart == false) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('product-sku')) {
        let addProductResp = await (await addProduct(urlParams.get('product-sku'))).json();
        console.log(addProductResp);
      }
    }
    this.setTermsConditionsText();
  }

  async setTermsConditionsText() {
    const response = await (await custInfoTermsCondition()).json();
    let productTermsConditions = '';
    response.lineItems.forEach((item) => {
      productTermsConditions += item.terms_conditions_text;
    });

    if (response.terms_conditions_text.trim() !== "") {
      this.termsConditionsText = response.terms_conditions_text.replace('{{additional-terms}}', productTermsConditions)
    } else {
      this.termsConditionsText = productTermsConditions;
    }

    this.termsConditionsText = `<div class="modal-terms">${this.termsConditionsText}</div>`;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.type === 'checkbox') {
      //sendCustomEvent('CustInfoComponentDataChange', { [name]: value });
    }

    this.setState({
      [name]: value
    });
  }

  async paynow(event) {

    const node = ReactDOM.findDOMNode(this);
    const form = node.querySelector('form');

    //validate form
    let formValidationResult = form.checkValidity();
    this.setState({
      validated: true
    });

    //confirm password validation
    if (this.state.password !== this.state.confirmPassword) {
      this.state.confirmPassword = "";
      formValidationResult = false;
    }


    // check form validity
    if (formValidationResult === false) {
      try {
        let invalidElement = document.querySelectorAll(".form-control:invalid");
        if (invalidElement && invalidElement.length === 0) {
          invalidElement = document.querySelectorAll(".is-invalid");
        }

        if (invalidElement.length) {
          window.jQuery('html, body').animate({
            scrollTop: window.jQuery(invalidElement[0]).offset().top - 100
          }, 1000);
        }
      } catch (error) { }

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      return false;
    }



    //everything looks good, proced to payment page
    const data = JSON.parse(JSON.stringify({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email.toLowerCase(),
      phone: this.state.phone.match(/\d/g).join(''),
      password: this.state.password,
      zip: this.state.zip,
      centerId: '', //this is default center id
      wantsPromotionText: this.state.wantsPromotionText,
      wantsAppointmentText: this.state.wantsAppointmentText,
      wantsPromotionEmail: this.state.wantsPromotionEmail,
      wantsContact: '1'
    }));

    //set nearest center id
    try {
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.open("GET", window.form_msvc_endpoint + '/v1/centers/zip/' + data.zip, false); // false for synchronous request
      xmlHttp.send(null);
      const centersJson = JSON.parse(xmlHttp.responseText);
      if (centersJson && centersJson.data && centersJson.data.length) {
        data.centerId = centersJson.data[0].siteID
      }
    } catch (error) { }
    
    data.wantsPromotionText = data.wantsPromotionText ? '1' : '0';
    data.wantsPromotionEmail = data.wantsPromotionEmail ? '1' : '0';
    data.wantsAppointmentText = data.wantsAppointmentText ? '1' : '0';

    this.setState((state) => { return { loader: true } });
    let response = await (await custInfoSubmit(data)).json();
    this.setState((state) => { return { loader: false } });

    if (response.status === 200) {
      //postDLEvent('checkout-customer-information-next');
      this.handleUserUpdate(response.user, constants.Account.Flow.SignUp);
    } else if (response.status === 500) { //TODO: Handle unknown error
      this.setState({ showCustomerActionRequiredModal: true });
      //postDLEvent('checkout-customer-service-modal-err');
      //sendCustomEvent('StoreStep', { "step": "needsCustomerService" });
    }
  }

  handleUserUpdate(updatedUser, parentFlow) {
    if (updatedUser) {
      if (updatedUser.status === constants.Account.Status.Active && parentFlow === constants.Account.Flow.SignUp && this.allowRedirect) {
        const redirectUrl = this.props['data-redirect'];
        if (redirectUrl && redirectUrl.trim().length) {
          this.allowRedirect = false;
          postDLEvent('checkout-customer-information-next-success');
          this.setState((state) => { return { loader: true } });
          setTimeout(function () {
            this.allowRedirect = true;
          }, 3000);
          setTimeout(function () {
            window.location = global.ii_erw_serverbase + redirectUrl;
          }, 500);
        }
      } else if (updatedUser.status === constants.Account.Status.LoginRequired) {
        this.setState({ showInvalidPasswordModal: true });
        //postDLEvent('lgtm-existing-account-login-modal');
        //sendCustomEvent('StoreStep', { "step": "loginRequired" });
      } else if (updatedUser.status === constants.Account.Status.WeakPassword) {
        this.setState({ password: "", confirmPassword: "" }, () => {
          this.paynow();
        });
      } else if (updatedUser.status === constants.Account.Status.NeedsCustomerService) {
        this.setState({ showCustomerActionRequiredModal: true });
        //postDLEvent('checkout-customer-service-modal');
        //sendCustomEvent('StoreStep', { "step": "needsCustomerService" });
      } else {
        this.context.updateAccountUser(updatedUser, true, true, constants.Account.Flow.SignUp);
      }
    }
  }

  termsConditionsClick(event) {
    //this.handleChange(event);
    this.setState({ showTermsConditionModal: true, agreeTerms: this.state.agreeTerms });

  }

  hideTermsConditionModal(isAgree) {
    this.setState({ showTermsConditionModal: false, agreeTerms: isAgree });
    //postDLEvent(isAgree ? 'checkout-terms-agree' : 'checkout-terms-disagree');
    //sendCustomEvent('CustInfoComponentDataChange', { "wantsContact": (isAgree ? "true" : "false") });
  }

  async zipKeyUp(event) {
    try {
      if (event.target.value) {
        if (event.target.value.match(/^\d{5}$/)) {
          event.target.classList.remove("is-invalid");
          event.target.setCustomValidity("");
        } else if (event.target.value.match(/^[a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d$/)) {
          event.target.classList.remove("is-invalid");
          event.target.setCustomValidity("");
        } else {
          event.target.classList.add("is-invalid");
          event.target.setCustomValidity("is-invalid");
        }
      }
    } catch (error) { }
  }

  invalidPasswordSignIn() {
    this.setState({ showInvalidPasswordModal: false });
    try {
      if (this.props.triggerSignIn) {
        this.props.triggerSignIn();
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="container" style={{ 'margin-top': '40px' }}>
        <ModalContext.Consumer>
          {modalState => {
            if (modalState.user && modalState.user.notify && modalState.user.status === constants.Account.Status.Active) {
              this.handleUserUpdate(modalState.user, modalState.user.parentFlow);
            }
          }}
        </ModalContext.Consumer>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <Form className="ideal-react-form" noValidate validated={this.state.validated}>
              <h3 className="info-title">Create an Account</h3>
              <br />
              <Form.Row className="full-width">
                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>First Name</Form.Label>
                  <Form.Control name="firstName" className="payloadInput" required pattern="^[a-zA-Z0-9](?:[ '.\-a-zA-Z]*[a-zA-Z0-9])$" minlength="2" maxlength="20" type="text" id="firstName" value={this.state.firstName} onChange={this.handleChange} />
                  <Form.Control.Feedback type="invalid">Please enter a valid name. No numbers allowed.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>Last Name</Form.Label>
                  <Form.Control name="lastName" className="payloadInput" required pattern="^[a-zA-Z0-9](?:[ '.\-a-zA-Z]*[a-zA-Z0-9])$" minlength="2" maxlength="20" type="text" id="lastName" value={this.state.lastName} onChange={this.handleChange} />
                  <Form.Control.Feedback type="invalid">Please enter a valid name. No numbers allowed.</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row className="full-width">
                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>Email</Form.Label>
                  <Form.Control name="email" className="payloadInput" required pattern="^[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9]+)?@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,5}$" minlength="4" maxlength="50" type="email" id="email" value={this.state.email} onChange={this.handleChange} />
                  <Form.Control.Feedback type="invalid">Invalid email.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>Mobile</Form.Label>
                  <InputMask
                    mask="(999) 999-9999"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    minlength="10" maxlength="15"
                    required pattern="^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$"
                  >
                    {(inputProps) => <Form.Control {...inputProps} name="phone" className="payloadInput masked-phone" type="text" id="telephone" />}
                  </InputMask>
                  <Form.Control.Feedback type="invalid">Invalid phone number.</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row className="full-width">
                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>Create Password</Form.Label>
                  <Form.Control name="password" required pattern="^(?=^.{8,20}$)(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[~!@#$%^*()_+`={}\[\]|\\:;',.?\/-]+)(?:[a-zA-Z0-9~!@#$%^*()_+`={}\[\]|\\:;',.?\/-]*)$" minlength="8" maxlength="20" type="password" id="password" value={this.state.password} onChange={this.handleChange} />
                  <Form.Control.Feedback type="invalid">Your password must be 8-20 characters long, contain letters (upper &amp; lowercase), numbers, and special characters.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>Confirm Password</Form.Label>
                  <Form.Control name="confirmPassword" required minlength="8" maxlength="20" type="password" id="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} />
                  <Form.Control.Feedback type="invalid">Passwords do not match.</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>


              <Form.Row className="full-width">
                <Form.Group as={Col} md="6">
                  <Form.Label><span class="mand-span">*</span>Zip/Postal Code</Form.Label>
                  <Form.Control name="zip" maxlength="8" className="payloadInput zipText" required type="text" id="zipcode"
                    value={this.state.zip} onChange={this.handleChange} onKeyUp={this.zipKeyUp} />
                  <Form.Control.Feedback type="invalid">Invalid ZIP/postal code.</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>


              <Form.Row className="full-width">
                <Form.Group className="mb-0">
                  <Form.Check type="checkbox" className="optionBox" id="wantsPromotionText" name="wantsPromotionText"
                    checked={this.state.wantsPromotionText} onChange={this.handleChange} label="Text Me For Our Best Deals Today**" />

                  <Form.Check type="checkbox" className="optionBox" id="wantsPromotionEmail" name="wantsPromotionEmail"
                    checked={this.state.wantsPromotionEmail} onChange={this.handleChange} label="Email me exciting promotions and specials from Ideal Image**" />

                  <Form.Check type="checkbox" className="optionBox" id="wantsAppointmentText" name="wantsAppointmentText"
                    checked={this.state.wantsAppointmentText} onChange={this.handleChange} label="Text me appointment reminders**" />
                </Form.Group>
              </Form.Row>

              {/* <div className="divider"></div>

              <Form.Row className="full-width">
                <Form.Group>
                  <div class="form-check">
                    <input required name="agreeTerms" type="checkbox" id="agreeTerms" class="form-check-input" checked={this.state.agreeTerms} onChange={this.termsConditionsClick} />
                    {window.location.href.indexOf('tg-level-01-ca') === -1 ?
                      <label for="agreeTerms" class="form-check-label"> I have read and agree to the<span className='anchor-link' name="agreeTerms" onClick={this.termsConditionsClick}> Membership Terms and Conditions and understand that such terms contain an agreement to arbitrate any disputes between us.</span></label>
                      : <label for="agreeTerms" class="form-check-label">I have read and agree to the<span className='anchor-link' name="agreeTerms" onClick={this.termsConditionsClick}> Lifetime Guarantee Membership Agreement, **Communication Preferences, and Terms &amp; Conditions.</span></label>
                    }
                    <Form.Control.Feedback type="invalid">You must agree to terms and conditions to proceed.</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Form.Row> */}

              <Form.Row className="full-width justify-content-center">
                <Form.Group>
                  <Button type="button" id="btn_paynow" disabled={(this.state.loader)} onClick={this.paynow}>{this.state.loader ? 'Wait....' : this.submitBtnText}</Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>

          <div style={{ display: this.state.loader ? 'block' : 'none' }}>
            <Loader />
          </div>

          <ModalComponent
            backdrop="static"
            modalHeading="Terms &amp; Conditions"
            modalContent={this.termsConditionsText}
            successText="I Agree"
            cancelText="Disagree"
            show={this.state.showTermsConditionModal}
            onHide={() => { this.hideTermsConditionModal(false) }}
            onAgree={() => { this.hideTermsConditionModal(true) }} />

          <ModalComponent
            useParser={false}
            modalHeading="Customer Center Action Required"
            modalContent={<CustomerCenterActionModal />}
            successText=""
            closeButton={true}
            size="sm"
            cancelText=""
            show={this.state.showCustomerActionRequiredModal}
            onHide={() => { this.setState({ showCustomerActionRequiredModal: false }); }} />

          <ModalComponent
            useParser={false}
            modalHeading="Validation"
            modalContent={<InvalidPasswordModal signInClick={() => { this.invalidPasswordSignIn(); }} />}
            successText=""
            closeButton={true}
            size="sm"
            cancelText=""
            show={this.state.showInvalidPasswordModal}
            onHide={() => { this.setState({ showInvalidPasswordModal: false }); }}
            onAgree={() => { this.setState({ showInvalidPasswordModal: false }); }} />
        </div>
      </div >
    );
  }
}

const Chooser = ({ id, title, desc, stc, onChange, sku, name, checked }) => {
  return (
    <div className={`chooser ${!desc ? 'no-desc' : ''} `}>
      <input id={sku} stc={stc} type="radio" className="free-gift-prd-radio" name={name} onChange={onChange} checked={checked === id} value={title} />
      <label for={sku}>
        {title}
        {desc ? <p>{desc}</p> : null}
      </label>
    </div>
  )
}

const InvalidPasswordModal = ({ signInClick }) => {
  return (
    <div className="customer-popup">
      <svg className="mt-3 mb-1" xmlns="http://www.w3.org/2000/svg" width="128" height="128" version="1.1" viewBox="0 0 128 128" xmlSpace="preserve">
        <path d="M64.281 84.291c-15.54 0-28.184-12.643-28.184-28.184V42.196c0-15.542 12.644-28.186 28.184-28.186 15.542 0 28.186 12.644 28.186 28.186v13.911c0 15.541-12.644 28.184-28.186 28.184zm0-66.281c-13.335 0-24.184 10.85-24.184 24.186v13.911c0 13.334 10.849 24.184 24.184 24.184 13.336 0 24.186-10.85 24.186-24.184V42.196c0-13.336-10.85-24.186-24.186-24.186z"></path>
        <path d="M102.332 114.01h-76.51c-6.518 0-11.819-5.303-11.819-11.82v-8.734c0-3.857 1.953-9.027 11.26-11.738l11.443-3.168a2 2 0 011.066 3.856l-11.417 3.16c-8.353 2.434-8.353 6.541-8.353 7.891v8.734c0 4.313 3.508 7.82 7.819 7.82h76.51c4.312 0 7.819-3.508 7.819-7.82v-8.734c0-3.643-2.816-6.299-8.372-7.896l-10.892-3.045a2 2 0 01-1.388-2.465 1.995 1.995 0 012.464-1.387l10.906 3.049c9.326 2.682 11.281 7.867 11.281 11.744v8.734c.002 6.516-5.299 11.819-11.817 11.819z"></path>
      </svg>

      <h4>There is already an account with this email address.</h4>

      <p>Click to <span className='anchor-link' onClick={signInClick}>Sign In</span></p>
      <p className="mb-3">Choose a different email or call customer service <br />
        <a className="mt-1" style={{ textDecoration: 'none' }} href={'tel:' + constants.CustomerInfo.CustomerCenterNumber} target='_self'>{formatPhoneNumber(constants.CustomerInfo.CustomerCenterNumber)}</a>
      </p>

      <a className="continue-btn" href={'tel:' + constants.CustomerInfo.CustomerCenterNumber} target='_self'>Continue</a>
    </div>
  )
}

const CustomerCenterActionModal = () => {
  return (
    <div className="customer-popup">
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 200 200">
        <path d="M115.19 151A35.42 35.42 0 0190 140.59L59.33 110a35.69 35.69 0 010-50.45 7.62 7.62 0 0110.75 0l13.26 13.22a7.44 7.44 0 010 10.5 14.92 14.92 0 000 21.1l12.21 12.22a14.93 14.93 0 0021.11 0 7.4 7.4 0 0110.49 0l13.26 13.26a7.58 7.58 0 010 10.74A35.41 35.41 0 01115.19 151zM64.71 60.89a4 4 0 00-2.83 1.17 32.07 32.07 0 000 45.35L92.51 138a32.06 32.06 0 0045.36 0 4 4 0 001.17-2.82 4 4 0 00-1.17-2.83l-13.26-13.26a3.83 3.83 0 00-5.41 0 18.5 18.5 0 01-26.19 0L86.89 113l1.29-1.26-1.34 1.26-6-6a18.54 18.54 0 010-26.2 3.81 3.81 0 000-5.4L67.53 62.06a3.94 3.94 0 00-2.82-1.17zM149.26 103.81h-.09a1.79 1.79 0 01-1.71-1.89 47 47 0 00-49.61-49.29 1.8 1.8 0 11-.2-3.59 50.56 50.56 0 0153.4 53.06 1.8 1.8 0 01-1.79 1.71z"></path>
        <path d="M137.53 103.23h-.09a1.8 1.8 0 01-1.7-1.89 35.24 35.24 0 00-37.23-37 1.8 1.8 0 11-.2-3.59 38.83 38.83 0 0141 40.77 1.8 1.8 0 01-1.78 1.71z"></path>
        <path d="M125.84 102.65h-.09a1.8 1.8 0 01-1.71-1.89A23.56 23.56 0 0099.17 76a1.81 1.81 0 01-1.9-1.69A1.79 1.79 0 0199 72.45a27.14 27.14 0 0128.67 28.49 1.79 1.79 0 01-1.83 1.71z"></path>
      </svg>

      <h4>You must call our customer service department in order to proceed with your purchase.</h4>
      <p className="mt-3 mb-4">
        <a href={'tel:' + constants.CustomerInfo.CustomerCenterNumber} target='_self'>{formatPhoneNumber(constants.CustomerInfo.CustomerCenterNumber)}</a>
      </p>


    </div>
  )
}

export default SignUp;
