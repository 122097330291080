function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '.' + match[2] + '.' + match[3];
  }
  return null;
}

function postDLEvent(eventName, eventCategory = 'Products', eventLabel = 'Lifetime Guarantee Membership') {
  try {
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   'event': eventName
    // });

    window.ga('send', 'event', {
      'eventCategory': eventCategory,
      'eventAction': eventName,
      'eventLabel': eventLabel
    });

  } catch (error) { console.error('Fail GA event'); console.log(error) }
}

function sendCustomEvent(eventName, eventData = null, extraProps = null) {
  setTimeout(function(){
    try {
      let event = new CustomEvent(eventName);    
      for (const key in extraProps) {
        if (Object.hasOwnProperty.call(extraProps, key)) {
          event[key] = extraProps[key];
        }
      }
      event.data = eventData; 
      window.dispatchEvent(event);
    } catch (error) {
      console.error(error);
    }
  },200);
}

window.sendCustomEvent = sendCustomEvent;

export { formatPhoneNumber, postDLEvent, sendCustomEvent };
