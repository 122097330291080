import React from 'react';

import spinner from './spinner.svg';


const loaderStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999
}

const Loader = () => {
    return ( <
        div style = { loaderStyles } >
        <
        img src = { spinner }
        /> < /
        div >
    )
}

export default Loader;