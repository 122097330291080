import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalContext } from "./auth-modalContext";

const AuthModal = () => {
  let modalContext = React.useContext(ModalContext);
  if (modalContext.modal) {
    return (
      <Modal show="true" size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered onHide={() => modalContext.handleModal()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalContext.modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalContext.modalContent}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => handleModal()}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  } else return null;
};

export default AuthModal;
