export const signInOrReset = (jsonData, isReset=false) => {
    var formData = new FormData();
    formData.append('action', 'ii_erw_login');
    for (const key in jsonData) {
        if (jsonData.hasOwnProperty(key)) {
            const element = jsonData[key];
            formData.append('data[' + key + ']', element);
        }
    }

    formData.append('data[method]', isReset? 'reset':'login');
    
    return fetch(window.ii_erw_serverpost, {
        method: 'POST',
        body: formData,
    });
};


export const signUp = (jsonData) => {
    var formData = new FormData();
    formData.append('action', 'ii_erw_signup');
    for (const key in jsonData) {
        if (jsonData.hasOwnProperty(key)) {
            const element = jsonData[key];
            formData.append('data[' + key + ']', element);
        }
    }
    return fetch(window.ii_erw_serverpost, {
        method: 'POST',
        body: formData,
    });
};

export const custInfoSubmit = (jsonData) => {
    var formData = new FormData();
    formData.append('action', 'ii_erw_custinfo');
    for (const key in jsonData) {
        if (jsonData.hasOwnProperty(key)) {
            const element = jsonData[key];
            formData.append('data[' + key + ']', element);
        }
    }
    return fetch(window.ii_erw_serverpost, {
        method: 'POST',
        body: formData,
    });
};

export const custInfoTermsCondition = () => {
    var formData = new FormData();
    formData.append('action', 'ii_erw_custinfo_terms_conditions');
    return fetch(window.ii_erw_serverpost, {
        method: 'POST',
        body: formData,
    });
};

export const addProduct = (productIdOrSku, type= 'productsku', forceEmptyCart = 'true') => {
    var formData = new FormData();
    formData.append('data[productIdOrSku]', productIdOrSku);
    formData.append('data[type]', type);
    formData.append('data[forceEmptyCart]', forceEmptyCart);
    formData.append('action', 'ii_add_to_cart');
    return fetch(window.ii_erw_serverpost, {
        method: 'POST',
        body: formData,
    });
};

export const checkCart = () => {
    var formData = new FormData();
    formData.append('action', 'ii_check_cart');
    return fetch(window.ii_erw_serverpost, {
        method: 'POST',
        body: formData,
    });
};