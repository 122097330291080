import './ltg-pdp.css';
import React from 'react';
import ReactDOM from 'react-dom';
import InputMask from 'react-input-mask';
import { Form, Col, Button } from 'react-bootstrap';
import { constants } from '../../helper/constant';
import { formatPhoneNumber, postDLEvent, sendCustomEvent } from '../../helper/functions';
import Login from './../login/login';
import { ModalComponent } from './../modal/modal';
import { ModalContext } from './../auth-modal/auth-modalContext';
import { custInfoSubmit, custInfoTermsCondition, addProduct, checkCart } from '../../services/api.service';
import { getCoords } from './../../services/centerlookup.service';
import Loader from '../loader';

import { motion, AnimatePresence } from "framer-motion"
import { Accordian, AccordianHead, AccordianBody, AccordianItem } from './../accordian';

class LTGPdp extends React.Component {

  static contextType = ModalContext;
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);

    let freeGiftProdId = null;
    if (this.props['free-gift-products']) {
      this.props['free-gift-products'].forEach(item => {
        if (item && item.selected) {
          freeGiftProdId = item.id;
        }
      });
    }

    let showZipCenterFields = true;
    if (this.props['showZipCenterFields']) {
      showZipCenterFields = this.props['showZipCenterFields'].toLowerCase() == 'true';
    }

    this.submitBtnText = 'Next';
    if (this.props['submitBtnText']) {
      this.submitBtnText = this.props['submitBtnText'];
    }

    this.state = {
      zip: '',
      centerId: '-1',
      freeGiftProdId: freeGiftProdId,
      validated: false,
      centers: [],
      noCenters: false,
      showZipCenterFields: showZipCenterFields,
      disabledCenters: false
    };

    this.allowRedirect = true;
    this.termsConditionsText = '';
    this.freeGiftHtml = { chooseLater: {}, mappedGifts: [] };

    this.handleChange = this.handleChange.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.checkChange = this.checkChange.bind(this);
    this.handleZipChange = this.handleZipChange.bind(this);
    this.renderFreeGifts = this.renderFreeGifts.bind(this);
    this.zipKeyUp = this.zipKeyUp.bind(this);

  }

  componentDidMount() {
    try {
      // sendCustomEvent('CustInfoComponentLoad', this.state, { component: ReactDOM.findDOMNode(this) });
      // sendCustomEvent('StoreStep', { "step": "customer-information" });
      // postDLEvent('ltgm-customer-information');

      const node = ReactDOM.findDOMNode(this);
      const form = node.closest('form');
      if (form) {
        form.setAttribute('noValidate', true);
        if (form.addEventListener) {
          form.addEventListener("submit", this.addToCart, false);  //Modern browsers
        } else if (form.attachEvent) {
          form.attachEvent('onsubmit', this.addToCart);            //Old IE
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.name === 'centerId') {
      if (value !== "-1") {
        if (target.selectedOptions.length > 0 && target.selectedOptions[0].getAttribute('corporate') !== undefined) {
          window.localStorage.setItem('corporate', target.selectedOptions[0].getAttribute('corporate'));
        }
        target.setCustomValidity("");
        target.classList.remove("is-invalid");
      } else {
        target.setCustomValidity("is-invalid");
        target.classList.add("is-invalid");
      }
    }

    this.setState({
      [name]: value
    });
  }

  async addToCart(event) {
    const node = ReactDOM.findDOMNode(this);
    const form = node.closest('form');

    //validate form
    let formValidationResult = form.checkValidity();
    this.setState({
      validated: true
    });


    //make sure atleast one center is selected - HANDLE DOPDOWN DISABLED SCENARIO
    if (this.state.centerId == "-1" && this.state.showZipCenterFields) {
      formValidationResult = false;
      if (this.state.centers && this.state.centers.length && this.state.noCenters == false) {
        const centerDropdown = node.querySelector(".centerDropdown");
        centerDropdown.setCustomValidity("is-invalid");
        centerDropdown.classList.add("is-invalid");
      }
    }

    //free gift validation
    const freeGiftSection = node.querySelector(".free-gifts-wrapper");
    if (freeGiftSection) {
      const feedbackElem = freeGiftSection.parentNode.querySelector('.free-gift-feedback-elem');
      freeGiftSection.classList.remove("is-invalid");
      node.querySelector("#centerDoesNotSupportFreeGift").style.display = "none";

      const selectedFreeProd = this.props['free-gift-products'].find(p => p.id === this.state.freeGiftProdId);
      if (selectedFreeProd) {
        if (selectedFreeProd.sku !== "free-gift-select-later") {
          let selectedServiceCode = "";
          selectedFreeProd.categories.forEach(category => {
            if (category.fields && category.fields['service_type_code']) {
              selectedServiceCode = category.fields['service_type_code'];
            }
          });

          let validServiceCodes = [];
          if (this.props.centers && this.props.centers.length) {
            this.props.centers.forEach((item) => {
              if (item.siteID === this.state.centerId) {
                item.services.forEach((service) => {
                  if (service) {
                    validServiceCodes.push(service['serviceTypeCode'].toString());
                  }
                });
              }
            });
          }

          if (validServiceCodes && validServiceCodes.indexOf(selectedServiceCode) === -1 && this.state.centerId != "-1") {
            formValidationResult = false;
            freeGiftSection.classList.add("is-invalid");
            feedbackElem.innerHTML = "Selected free gift is not supported by center you choosed. Please select another free gift.";
            node.querySelector("#centerDoesNotSupportFreeGift").style.display = "block";
          }
        }
      } else {
        formValidationResult = false;
        freeGiftSection.classList.add("is-invalid");
        feedbackElem.innerHTML = "Please select at least one free gift product.";
      }

      if (formValidationResult) {
        freeGiftSection.classList.add("is-valid");
        feedbackElem.innerHTML = "";
        node.querySelector("#centerDoesNotSupportFreeGift").style.display = "none";
      }
    }

    // check form validity
    if (formValidationResult === false) {
      try {
        let invalidElement = document.querySelectorAll(".form-control:invalid");
        if (invalidElement && invalidElement.length === 0) {
          invalidElement = document.querySelectorAll(".is-invalid");
        }

        if (invalidElement.length) {
          invalidElement[0].classList.add("is-invalid");
          window.jQuery('html, body').animate({
            scrollTop: window.jQuery(invalidElement[0]).offset().top - 100
          }, 1000);
        } else {
          if (this.state.noCenters) {
            //postDLEvent('ltgm-customer-service-modal-no-center');
            //sendCustomEvent('StoreStep', { "step": "needsCustomerService" });
          }
        }
      } catch (error) { 
        console.error(error);
      }

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }     
      return false;
    }

    
  }


  checkChange(prodId, stc) {
    this.setState({ freeGiftProdId: prodId });
    //sendCustomEvent('CustInfoComponentDataChange', { "serviceTypeCode": stc });
  }

  async handleZipChange() {
    if (this.state.showZipCenterFields == false)
      return false;

    this.setState((state) => { return { loader: true } });
    let centers = [];
    const node = ReactDOM.findDOMNode(this);
    const zipCodeField = node.querySelector(".zipText");
    let centersDisabled = [];
    zipCodeField.classList.remove("is-invalid");
    zipCodeField.setCustomValidity("");
    const geoCoordResp = await getCoords(this.state.zip);
    if (geoCoordResp.isValidZip) {
      const nearByCenters = geoCoordResp.centers;
      if (nearByCenters) {
        this.props.centers = nearByCenters;
        for (const [index, value] of nearByCenters.entries()) {
          let optionStatus = value.services.some(s => s.serviceTypeCode === constants.LTGServiceCode);
          centersDisabled.push(optionStatus);
          centers.push(<option value={value.siteID} index={index} corporate={value.corporate} disabled={!optionStatus}>{value.webName}</option>)
        }
      }
    } else {
      zipCodeField.setCustomValidity("is-invalid");
      zipCodeField.classList.add("is-invalid");
    }

    const parseCenterDisable = centersDisabled.length ? centersDisabled.every(item => item === false) : false;
    this.setState({ centers: centers, centerId: "-1", noCenters: centers.length == 0, disabledCenters: parseCenterDisable });
    this.setState((state) => { return { loader: false } });
  }

  async zipKeyUp(event) {
    try {
      event.target.classList.add("is-invalid");
      if (event.target.value) {
        if (event.target.value.match(/^\d{5}$/)) {
          event.target.classList.remove("is-invalid");
          this.handleZipChange();
        } else if (event.target.value.match(/^[a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d$/)) {
          event.target.classList.remove("is-invalid");
          this.handleZipChange();
        }
      }
    } catch (error) {

    }
  }

  renderFreeGifts() {
    if (this.props['free-gift-products'] && this.props['free-gift-products'].length) {
      const sortedItems = {};
      this.props['free-gift-products'].forEach(item => {
        if (item.categories.length > 1) {
          const cat = item.categories[1];
          sortedItems[cat.id] = sortedItems[cat.id] || cat;
          sortedItems[cat.id]['skus'] = [];
        } else {
          this.freeGiftHtml.chooseLater = item;
          this.freeGiftHtml.chooseLater.id = item.id;
        }
      });

      this.props['free-gift-products'].forEach(item => {
        if (item.categories.length > 1) {
          const cat = item.categories[1];
          sortedItems[cat.id]['skus'].push(item);
        }
      });

      let mappedGifts = [];
      for (const prop in sortedItems) {
        const cateory = sortedItems[prop];
        const item = <AccordianItem key={cateory.id}>
          <AccordianHead description={cateory.description} title={cateory.name} icon={cateory.thumb_url} />
          <AccordianBody>
            {
              cateory.skus.map(sku => <Chooser id={sku.id} sku={sku.sku} title={sku.name} desc={sku.desc} stc={sku.stc} name="free-gift" checked={this.state.freeGiftProdId} onChange={() => { this.checkChange(sku.id, sku.stc) }} />)
            }
          </AccordianBody>
        </AccordianItem>;

        mappedGifts.push(item);
      }
      this.freeGiftHtml.mappedGifts = mappedGifts;
    }
  }

  render() {
    this.renderFreeGifts();
    return (
      <div style={{ 'margin-top': '20px' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div>
              {/* <Form className="ideal-react-form" noValidate validated={this.state.validated}> */}

              {this.freeGiftHtml.mappedGifts && this.freeGiftHtml.mappedGifts.length > 0 &&
                <div className="free-gifts">
                  <Form.Label className="ii-label bd-t">Select Your Free Treatment</Form.Label>
                  <div className="free-gifts-wrapper form-control">
                    {/* <h3>Receive a free popular treatment valued at over $250</h3> */}
                    <Accordian>
                      {this.freeGiftHtml.mappedGifts}
                    </Accordian>
                    <Chooser id={this.freeGiftHtml.chooseLater.id} sku={this.freeGiftHtml.chooseLater.sku} title={this.freeGiftHtml.chooseLater.name} stc={this.freeGiftHtml.chooseLater.stc} name="free-gift" checked={this.state.freeGiftProdId} onChange={() => { this.checkChange(this.freeGiftHtml.chooseLater.id, this.freeGiftHtml.chooseLater.stc) }} />
                  </div>
                  <Form.Control.Feedback className="free-gift-feedback-elem" type="invalid">Please select at least one free gift product.</Form.Control.Feedback>
                </div>
              }

              {this.state.showZipCenterFields &&
                <React.Fragment>
                  <Form.Label className="ii-label bd-b">Select Zip/Postal code & City to find the treatments in your location.</Form.Label>
                  <Form.Row className="full-width" style={{ 'padding': '0px' }}>
                    <Form.Group as={Col} md="6" style={{ 'padding': '0px 5px 0px 0px' }}>
                      <Form.Label>Zip/Postal Code</Form.Label>
                      <Form.Control name="zip" maxlength="8" className="payloadInput zipText" required type="text" id="zipcode" value={this.state.zip} onChange={this.handleChange} onKeyUp={this.zipKeyUp} />
                      <Form.Control.Feedback type="invalid">Invalid ZIP/postal code.</Form.Control.Feedback>
                      {(this.state.disabledCenters && this.state.zip) && <Form.Text className="error-message">LTGM is not sold for this ZIP Code.</Form.Text>}
                    </Form.Group>

                    <Form.Group as={Col} md="6" style={{ 'padding': '0px 0px 0px 5px' }}>
                      <Form.Label>Center</Form.Label>
                      <select name="centerId" id="siteid" disabled={this.state.centers == undefined || this.state.centers.length == 0} value={this.state.centerId} className="form-control payloadInput centerDropdown" required onChange={this.handleChange}>
                        <option value="-1"></option>
                        {this.state.centers}
                      </select>
                      <Form.Text id="centerDoesNotSupportFreeGift" className="invalid-feedback" style={{ 'display': 'none', 'color': 'red' }}>Selected center does not support free gift you have selected.</Form.Text>
                      {!this.state.disabledCenters && <Form.Control.Feedback type="invalid">Please select a center</Form.Control.Feedback>}
                    </Form.Group>
                  </Form.Row>
                </React.Fragment>
              }
              {/* </Form> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Chooser = ({ id, title, desc, stc, onChange, sku, name, checked }) => {
  return (
    <div className={`chooser ${!desc ? 'no-desc' : ''} `}>
      <input id={sku} stc={stc} type="radio" className="free-gift-prd-radio" name={name} onChange={onChange} checked={checked === id} value={id} />
      <label for={sku}>
        {title}
        {desc ? <p>{desc}</p> : null}
      </label>
    </div>
  )
}

export default LTGPdp;