import React from 'react';
import { constants } from './../../../helper/constant';
import AccountPhoneEmailVerification from './../phoneEmailVerification/phoneEmailVerification';

const useModal = () => {

    let [modal, setModal] = React.useState(false);
    let [modalContent, setModalContent] = React.useState('I am the Modal Content');
    let [modalHeading, setModalHeading] = React.useState('Modal heading');
    let [user, setUser] = React.useState({});

    let handleModal = (visible) => {
        setModal(visible);
    };

    let deepCompare = (arg1, arg2) => {
        if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
            if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]') {
                if (Object.keys(arg1).length !== Object.keys(arg2).length) {
                    return false;
                }
                return (Object.keys(arg1).every(function (key) {
                    return deepCompare(arg1[key], arg2[key]);
                }));
            }
            return (arg1 === arg2);
        }
        return false;
    }

    let updateAccountUser = (userObj, notify = true, visible = true, parentFlow = constants.Account.Flow.SignIn) => {

        const updatedUser = { ...user, ...userObj, ...{ notify: notify } };
        if (userObj !== undefined && userObj !== null && deepCompare(user, updatedUser) === false) {
            updatedUser.parentFlow = parentFlow;
            setUser(updatedUser);
        }

        if (updatedUser.status === constants.Account.Status.NeedsVerification || updatedUser.status === constants.Account.Status.NeedsVerificationGuest) {
            setModalHeading('OTP Verification');
            setModalContent(<AccountPhoneEmailVerification parentFlow={parentFlow} user={updatedUser} disablePhoneOTP={false} updateAccountUserCallback={updateAccountUser} />);
        } else {
            visible = false;
        }

        if (visible === true || visible === false) {
            handleModal(visible);
        }

    };

    return { user, updateAccountUser, modal, modalContent, modalHeading, handleModal };
}

export default useModal;