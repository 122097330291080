import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ModalProvider } from './component/auth-modal/auth-modalContext';
import Login from './component/login/login.js';
import CustInfo from './component/cust-info/cust-info.js';

import { ModalProvider as AccountModalProvider } from './component/account/auth-modal/auth-modalContext';
import SignIn from './component/account/signin/signin';
import Signup from './component/account/signup/signup.js';
import Account from './component/account/account';

import LTGPdp from './component/ltg-pdp/ltg-pdp';
import { constants } from './helper/constant';
import { custInfoTermsCondition } from './services/api.service';

window.ii_consts= constants;
window.ii_get_terms_api = custInfoTermsCondition;

const loginTargets = document.querySelectorAll('.ii_erw-login-root');
Array.prototype.forEach.call(loginTargets, (target) => { ReactDOM.render(<ModalProvider><Login data-redirect={target.attributes['data-redirect'].value} /></ModalProvider>, target) });

const signInTargets = document.querySelectorAll('.ii_erw-signin-root');
Array.prototype.forEach.call(signInTargets, (target) => { ReactDOM.render(<AccountModalProvider><SignIn data-redirect={target.attributes['data-redirect'].value} /></AccountModalProvider>, target) });

const accountTargets = document.querySelectorAll('.ii_erw-account-root');
Array.prototype.forEach.call(accountTargets, (target) => { 
    let signInDataRedirect = "";
    let signUpDataRedirect = "";
    let showGuestCheckout= "true";
    try {    
        if (target.attributes['show-guest-checkout']) {
            showGuestCheckout = target.attributes['show-guest-checkout'].value;
        }
        
        if (target.attributes['sign-in-redirect-url']) {
            signInDataRedirect = target.attributes['sign-in-redirect-url'].value;
        }

        if (target.attributes['sign-up-redirect-url']) {
            signUpDataRedirect = target.attributes['sign-up-redirect-url'].value;
        }
    } catch (error) {
        console.log(error);
    }
    ReactDOM.render(<AccountModalProvider><Account show-guest-checkout={showGuestCheckout} sign-in-redirect-url={signInDataRedirect} sign-up-redirect-url={signUpDataRedirect} /></AccountModalProvider>, target);
 });

const signupTargets = document.querySelectorAll('.ii_erw-signup-root');
Array.prototype.forEach.call(signupTargets, target => ReactDOM.render(<AccountModalProvider><Signup data-redirect={target.attributes['data-redirect'].value} /></AccountModalProvider>, target));

const custInfoTargets = document.querySelectorAll('.ii_erw-custinfo-root');
Array.prototype.forEach.call(custInfoTargets, target => {
    let freeGift = {};
    let redirectPath = '';
    let user = {};
    try {
        freeGift = JSON.parse(target.getAttribute('data-free-gift'));
        redirectPath = target.attributes['data-redirect'].value;
        user = JSON.parse(target.getAttribute('data-user'));
    } catch (error) {
        console.log(error);
    }
    ReactDOM.render(<ModalProvider><CustInfo free-gift-products={freeGift} data-redirect={redirectPath} data-user={user} /></ModalProvider>, target);
    target.removeAttribute('data-free-gift');
});

const ltgPdpTargets = document.querySelectorAll('.ii_erw-ltg-pdp');
Array.prototype.forEach.call(ltgPdpTargets, (target) => {
    let freeGift = {};
    try {
        freeGift = JSON.parse(target.getAttribute('data-free-gift'));
    } catch (error) {
        console.log(error);
    }
    ReactDOM.render(<LTGPdp free-gift-products={freeGift} />,target);
});