import React from "react";
import useModal from "./useAuthModal";
import AuthModal from "./auth-modal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
    return (
        <Provider value={{ ...useModal() }}>
            <AuthModal />
            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };