export const constants = {
    Account: {
        Status: {
            Active: 'active',
            LoginRequired: 'loginRequired',
            InvalidPassword: 'invalidPassword',
            WeakPassword: 'weakPassword',
            NeedsVerification: 'needsVerification',
            NeedsVerificationGuest: 'needsVerificationGuest',
            NeedsCustomerService: 'needsCustomerService'
        },
        Flow:{
            SignIn:'SignIn',
            SignUp:'SignUp',
            ResetPassword:'ResetPassword'
        },
        GuestCheckoutClickedEventName: 'GuestCheckoutClicked'
    },
    CustomerInfo: {
        CustomerCenterNumber: '8006857032',
        FlowName: 'custinfo'
    },
    LTGServiceCode: 142
}