import './login.css';
import { constants } from '../../helper/constant';
import { Form, Col } from 'react-bootstrap';
import React, { createRef } from 'react';
import { ModalContext } from './../auth-modal/auth-modalContext';
import { signInOrReset } from './../../services/api.service';
import { Toast } from '../alert/toast';

import { motion } from "framer-motion";

class Login extends React.Component {

  static contextType = ModalContext;
  constructor(props) {
    super(props);
    let showReset = true;
    if (props['show-reset-password'] && props['show-reset-password'] === "false") {
      showReset = false;
    }

    this.state = {
      email: '',
      password: '',
      showReset: showReset,
      toasts: {
        invalidEmail: false,
        resetSuccess: false,
        weakPassword: false
      },
      loading: false
    };

    this.passwordRef = createRef();

    this.handleChange = this.handleChange.bind(this);
    this.signIn = this.signIn.bind(this);
    this.handleUserUpdate = this.handleUserUpdate.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.showResetMessage = this.showResetMessage.bind(this);
    this.switchFlow = this.switchFlow.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  switchFlow(value) {
    this.setState({ email: '', password: '', toasts: { invalidEmail: false, resetSuccess: false, weakPassword: false} });
    this.context.setIsResetFlow(value);
  }

  async signIn(event) {
    event.preventDefault();
    const data = {
      email: this.state.email,
      password: this.state.password
    };

    this.setState((state, props) => {
      return { toasts: { ...state.toasts, invalidEmail: false, resetSuccess: false }, loading: true }
    });

    let response = await (await signInOrReset(data, this.context.isResetFlow)).json();

    const passwordField = this.passwordRef.current;
    if (response.status === 404) {
      this.setState((state, props) => {
        return { toasts: { ...state.toasts, invalidEmail: true }, loading: false }
      });
    } else {
      if (response && response.user && response.user.status === constants.Account.Status.WeakPassword) {
        this.setState((state, props) => {
          return { toasts: { ...state.toasts, weakPassword: true }, loading: false }
        });
        passwordField.classList.add('is-invalid');
        passwordField.focus();
      } else {
        this.setState((state, props) => {
          return { toasts: { ...state.toasts, weakPassword: false }, loading: false }
        });
        passwordField.classList.remove('is-invalid');
        this.context.updateUser({ ...data, ...response.user }, true, true, this.context.isResetFlow);
      }
    }
  }

  handleUserUpdate(updateUser) {
    if (this.context.isResetFlow && this.context.user.resetSuccessfull) {
      this.showResetMessage();
    } else {
      if (updateUser && updateUser.status === constants.Account.Status.Active) {
        const redirectUrl = this.props['data-redirect'];
        if (redirectUrl && redirectUrl.trim() !== "") {
          window.location.href = global.ii_erw_serverbase + redirectUrl;
        }
        if (this.props.onSuccess) {
          this.props.onSuccess(updateUser);
        }
      }
    }
  }

  closeAlert(selector) {
    try { document.querySelector(selector).closest('.userMessage').style.display = 'none'; } catch (e) { }
  }

  showResetMessage() {
    this.setState((state, props) => { return { toasts: { ...state.toasts, resetSuccess: true } } });
    this.context.setIsResetFlow(false);
  }

  render() {
    const variants = {
      hidden: { y: 10, opacity: 0 },
      visible: { y: 0, opacity: 1 },
      exit: { y: -10, opacity: 0 },
    }

    return (
      <motion.div transition={{ stiffness: 0, duration: 0.6}} variants={variants}>
        <ModalContext.Consumer>
          {modalState => {
            if (modalState.user && modalState.user.changed) {
              this.handleUserUpdate(modalState.user);
            }
          }}
        </ModalContext.Consumer>

        <form className="ideal-form">
          <h2 className="info-title">{this.context.isResetFlow === false ? 'Sign In' : 'Reset Password'}</h2>

          {this.state.toasts.invalidEmail && (<Toast title="There was a problem" message="Invalid Email or Password." variant="warning" />)}

          {this.state.toasts.resetSuccess && (<Toast title="Success" message="Password Reset Successfull. Please login now." variant="success" />)}

          <Form.Row className="w-736">
            <Form.Group as={Col} md="6">
              <div className="form-item">
                <input
                  type="email" placeholder="Email" name="email" className="form-control"
                  value={this.state.email} onChange={this.handleChange} />
              </div>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <div className="form-item">
                <input autocomplete="off" ref={this.passwordRef}
                  type="password" placeholder={this.context.isResetFlow === false ? 'Password' : 'New Password'} name="password" id="password" className="form-control"
                  value={this.state.password} onChange={this.handleChange} />

                {this.state.toasts.weakPassword && (
                  <div className='weakPasswordValidationMsgBox'>
                    <Toast title="Weak Password Detected" message="Your password must be 8-20 characters long, contain letters(Upper &amp; Lower case), symbols and numbers, and must not contain spaces, special characters, or emoji." variant="warning" />
                  </div>  
                )}
              </div>
            </Form.Group>
          </Form.Row>

          <div className="form-item">
            <button disabled={this.state.loading} type="submit" id="ii-wc-login" className="form-button" onClick={this.signIn}>{this.state.loading ? 'wait...' : (this.context.isResetFlow === false ? 'Sign In' : 'Reset Password')}</button>
          </div>

          {this.state.showReset && <a className="form-toggle" onClick={() => { this.switchFlow(!this.context.isResetFlow) }}>{this.context.isResetFlow === false ? 'Forgot Password?' : 'Sign In?'} </a>}
        </form>
      </motion.div>

    );
  }
}

export default Login;