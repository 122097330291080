import centers from './centers';

function deg2rad(deg) {
    return deg * Math.PI / 180
}

function getDistance(lat1, lon1, lat2, lon2) {
    var R = 3958.8; // Radius of the earth in mi
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance
    return d;
}

function precise_round(num, dec) {
    if ((typeof num !== 'number') || (typeof dec !== 'number')) return false;
    var num_sign = num >= 0 ? 1 : -1;
    return (Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec);
}
// ----- /haversine function ----->>

// ----- getcenters function----->>
function getCenters(geocodeResp, zipcode) {
    let result = { isValidZip: true, centers: [] };
    try {
        let centersArray = [];
        let validZip = true;
        let filterResult = geocodeResp.results[0].address_components.find(a => a["long_name"] == zipcode);
        if (filterResult != undefined) {
            validZip = filterResult.types.includes('postal_code');
        } else {
            validZip = false;
        }
        if (validZip) {
            let lat1, lat2, lon1, lon2;
            lat1 = lat2 = geocodeResp.results[0].geometry.location.lat;
            lon1 = lon2 = geocodeResp.results[0].geometry.location.lng;
            let len = centers['data'].length;

            // ----- begin centers loop ----->>

            for (var i = 0; i < len; i++) {
                lat2 = centers['data'][i].latitude;
                lon2 = centers['data'][i].longitude;
                let miles = precise_round(getDistance(lat1, lon1, lat2, lon2), 2);
                let showOnWebsite = centers['data'][i].showOnWebsite;
                // if(miles <= 50 && showOnWebsite != 0){
                if (miles <= 50) {
                    centersArray.push({
                        identifier: centers['data'][i].identifier,
                        siteID: centers['data'][i].siteID,
                        webName: centers['data'][i].webName,
                        corporate: centers['data'][i].corporate,
                        distance: miles,
                        latitude: centers['data'][i].latitude,
                        longitude: centers['data'][i].longitude,
                        services: centers['data'][i].services,
                        referral_sources: centers['data'][i].referral_sources
                    });
                } // endif filter conditions
            } // end for loop

            // ----- /begin centers loop ----->>
            centersArray.sort(function (a, b) {
                return a.distance - b.distance
            });

            if (centersArray.length > 0) {
                centersArray = centersArray.slice(0, 5);
            }
        }
        else {
            result.isValidZip = false;
        }
        result.centers = centersArray;
    } catch (err) {
        result.isValidZip = false;
    } finally {
        return result;
    }
}
// -----/getcenters function----->>

// ----- zipcode processor ----->>
async function getCoords(zipcode) {

    if (zipcode === null || zipcode === undefined || zipcode.trim() === "") {
        return null;
    }

    // ------ insert space in ca postal codes ----->>
    let regex = /^[a-zA-z]/gm;
    if (regex.exec(zipcode) !== null) {
        let regexSpace = /\s/gm;
        if (regexSpace.exec(zipcode) == null) {
            zipcode = zipcode.slice(0, 3) + " " + zipcode.slice(3);
        }
    }
    // ------ /insert space in ca postal codes ----->>

    let gapiurl = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyB66gOXAhbJzUmFJ3W3EJQUsjxVeBPbrh4&address=' + encodeURI(zipcode);
    try {

        let response = await (await fetch(gapiurl)).json();
        window.lastJson = response;

        // ----- get location data from service ----->>
        return getCenters(response, zipcode);
    } catch (err) {
        console.log('Error: ' + err.name);
        console.log('Message: ' + err.message);
        console.log('Trace: ' + err.stack);
    }
} //end getcoords function    

export { getCoords };