import React from 'react';
import ReactDOM from 'react-dom';
import { Form, Button } from 'react-bootstrap';
import './phoneEmailVerification.css';
import { signInOrReset, custInfoSubmit } from '../../services/api.service';
import { Toast } from '../alert/toast';
import { constants } from '../../helper/constant';
import { postDLEvent, sendCustomEvent } from '../../helper/functions';

import mailIcon from './mailicon.png';
import phoneIcon from './phoneico.png';

class PhoneEmailVerification extends React.Component {

  constructor(props) {
    super(props);

    this.phoneEmailChooseRadioChange = this.phoneEmailChooseRadioChange.bind(this);
    this.setPreferredValidation = this.setPreferredValidation.bind(this);
    this.validateCode = this.validateCode.bind(this);
    this.skipIfMax = this.skipIfMax.bind(this);
    this.resetState = this.resetState.bind(this);

    this.state = this.resetState();

    this.props.isResetFlow = this.props.isResetFlow.toLowerCase() === 'true';

    delete this.props.user.guest;
    delete this.props.user.phoneCode;
    delete this.props.user.emailCode;
    delete this.props.user.guest_id;
    delete this.props.user.changed;

    postDLEvent('lgtm-pin-modal');
  }

  componentDidMount() {
    sendCustomEvent('StoreStep', { "step": "needsVerification" });
    if (this.props.disablePhoneOTP) {
      this.setPreferredValidation(); //TODO: This is temparory until SF fix phone OTP.
    }
  }

  resetState(setFlag = false) {
    const state = { showChooser: true, showEmailValidationForm: false, isEmailPrefered: true, otpError: false, otpLoader: false };
    if (setFlag) {
      this.setState(state, () => {
        if (this.props.disablePhoneOTP) {
          this.setPreferredValidation(); //TODO: This is temparory until SF fix phone OTP.
        }
      });
    }
    return state;
  }

  phoneEmailChooseRadioChange(isEmailSelected) {
    const preferredValidationSelection = { isEmailPrefered: isEmailSelected };
    this.setState({ ...this.state, ...preferredValidationSelection });
  }

  async setPreferredValidation() {
    let nextValidationForm = {};
    nextValidationForm.showChooser = false;
    if (this.state.isEmailPrefered) {
      postDLEvent('lgtm-pin-email');
      nextValidationForm['showEmailValidationForm'] = true;
    } else {
      postDLEvent('lgtm-pin-phone');
      nextValidationForm['showEmailValidationForm'] = false;
    }
    this.setState({ ...this.state, ...nextValidationForm });
    const data = {
      ...this.props.user, ...{
        preferredValidation: this.state.isEmailPrefered ? 'email' : 'phone'
      }
    };
    if (this.props.parentFlow == constants.CustomerInfo.FlowName) {
      await custInfoSubmit(data);
    } else {
      await signInOrReset(data, this.props.isResetFlow);
    }
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const otp1 = node.querySelector('#otp-1');
      if (otp1) { otp1.focus(); }
    }

  }

  skipIfMax(event) {
    const max = parseInt(event.target.getAttribute('maxlength'));
    if (event.target.value.length >= max && event.target.nextElementSibling) {
      event.target.nextElementSibling.focus();
    } else if (event.target.value.length === 0 && event.target.previousElementSibling) {
      event.target.previousElementSibling.focus();
    }
  }

  async validateCode(event) {

    const form = event.currentTarget.closest("form");

    if (form.checkValidity()) {
      this.setState({ otpError: false, otpLoader: true });
      const otp1 = form.querySelector('#otp-1');
      const otp2 = form.querySelector('#otp-2');
      const otp3 = form.querySelector('#otp-3');
      const otp4 = form.querySelector('#otp-4');
      const data = {
        ...this.props.user, ...{
          preferredValidation: this.state.isEmailPrefered ? 'email' : 'phone',
          [this.state.isEmailPrefered ? 'emailCode' : 'phoneCode']: (otp1.value + '' + otp2.value + '' + otp3.value + '' + otp4.value)
        }
      };

      if (this.props.parentFlow == constants.CustomerInfo.FlowName) {
        delete data.guest;
        const response = await (await custInfoSubmit(data)).json();
        this.setState({ otpLoader: false });
        if (response.user.status === 'active') {
          postDLEvent('lgtm-pin-valid');
          this.props.updateUserCallback({ ...response.user, ...{ changed: true } }, true, false, this.props.isResetFlow, this.props.parentFlow);
        }
        else {
          if (response.user.status === 'invalidEmailVerification' || response.user.status === 'invalidPhoneVerification') {
            this.setState({ otpError: true });
            otp1.value = otp2.value = otp3.value = otp4.value = '';
          }
        }
      } else {
        const response = await (await signInOrReset(data, this.props.isResetFlow)).json();
        this.setState({ otpLoader: false });
        if (this.props.isResetFlow) {
          if (response.status === 200) {
            this.props.updateUserCallback({ resetSuccessfull: true }, true, false, this.props.isResetFlow, this.props.parentFlow);
          } else {
            if (response.user.status === 'invalidEmailVerification' || response.user.status === 'invalidPhoneVerification') {
              this.setState({ otpError: true });
              otp1.value = otp2.value = otp3.value = otp4.value = '';
            }
          }
        } else {
          if (response.user.status === 'active') {
            this.props.updateUserCallback({ ...response.user, ...{ changed: true } }, true, false, this.props.isResetFlow, this.props.parentFlow);
          }
          else {
            if (response.user.status === 'invalidEmailVerification' || response.user.status === 'invalidPhoneVerification') {
              this.setState({ otpError: true });
              otp1.value = otp2.value = otp3.value = otp4.value = '';
            }
          }
        }
      }
    }
  }

  render() {
    const emailTxt = <div><img style={{ width: '52px', height: '40px', objectFit: 'cover', marginRight: '20px' }} src={mailIcon} />Email</div>;
    const phoneTxt = <div><img style={{ width: '52px', height: '52px', objectFit: 'cover', marginRight: '20px' }} src={phoneIcon} />Mobile</div>;
    return (
      <div>
        { this.state.showChooser &&
          <Form className="verifiction-form ideal-react-form">
            <Form.Group>
              <Form.Label className="modal-title-label">To verify your identity, we'll send you a <span style={{ 'white-space': 'nowrap' }}>one-time</span> 4 digit PIN. How would you like to receive your PIN?</Form.Label>
            </Form.Group>
            <div className="divider divider__dark"></div>
            <Form.Group>
              <Form.Check className="align-center" type="radio" label={emailTxt} id="emailPrefered" name="phoneEmailChooseFormRadio" checked={this.state.isEmailPrefered} onChange={() => { this.phoneEmailChooseRadioChange(true) }} />
            </Form.Group>
            <div className="spacer"></div>
            <Form.Group>
              <Form.Check className="align-center" type="radio" label={phoneTxt} id="phonePrefered" name="phoneEmailChooseFormRadio" checked={!this.state.isEmailPrefered} onChange={() => { this.phoneEmailChooseRadioChange(false) }} />
            </Form.Group>
            <div className="divider divider__dark"></div>
            <Form.Group>
              <Button type="button" onClick={this.setPreferredValidation}>Next</Button>
            </Form.Group>
          </Form>}

        {this.state.showChooser === false &&
          <Form className="ideal-react-form">
            <Form.Group className="mb-0">
              <Form.Label className="modal-title-label">
                {this.state.showEmailValidationForm ? <span>The email address which you entered is associated with an existing guest</span> : <span>The phone number which you entered is <br />associated with an existing guest</span>}
              </Form.Label>
              <Form.Label className="text-muted">
                {this.state.showEmailValidationForm ? 'Please enter the 4 digit PIN that has been sent to ' + this.props.user.email : 'Please enter the 4 digit PIN that has been sent to your phone number.'}
              </Form.Label>
              <div className={`otp-field ${this.state.otpError && ('otp-field-error')}`}>
                <div className="d-flex justify-content-between">
                  <Form.Control required type="text" pattern="\d*" maxlength="1" className="otp-box" id="otp-1" onKeyUp={this.skipIfMax} />
                  <Form.Control required type="text" pattern="\d*" maxlength="1" className="otp-box" id="otp-2" onKeyUp={this.skipIfMax} />
                  <Form.Control required type="text" pattern="\d*" maxlength="1" className="otp-box" id="otp-3" onKeyUp={this.skipIfMax} />
                  <Form.Control required type="text" pattern="\d*" maxlength="1" className="otp-box" id="otp-4" onKeyUp={this.skipIfMax} style={{ 'float': 'none' }} />
                </div>
              </div>
              <Form.Label className="text-muted mb-0">
                Didn't receive the PIN? <Form.Label className="anchor-link" onClick={() => { this.resetState(true) }}> Resend PIN </Form.Label>
              </Form.Label>

              {this.state.otpError && (
                <div className='validationMsgBox'>
                  <Toast title="Invalid 4 digit PIN entered." message="Please try again." variant="warning" />
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <Button type="button" disabled={this.state.otpLoader} onClick={(event) => { this.validateCode(event) }}>{this.state.otpLoader ? 'Validating...' : 'Validate'}</Button>
            </Form.Group>

          </Form>}
      </div>
    );
  }
}

export default PhoneEmailVerification;
