import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import parse from 'html-react-parser';
import './modal.css';

export const ModalComponent = (props) => {
  if (props.useParser === undefined) {
    props.useParser = true;
  }
  return (
    <Modal size={props.size ? props.size : 'lg'} aria-labelledby="contained-modal-title-vcenter" centered {...props}>
      <Modal.Header closeButton={props.closeButton ? true : false}>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalHeading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {props.useParser ? parse(props.modalContent) : props.modalContent}
      </Modal.Body>
      {
        ((props.successText && props.successText !== "") || (props.cancelText && props.cancelText !== "")) && (
          <Modal.Footer>
            {props.successText && props.successText !== "" && <Button variant="primary" onClick={props.onAgree}>{props.successText}</Button>}
            {props.cancelText && props.cancelText !== "" && <Button variant="secondary" onClick={props.onHide}>{props.cancelText}</Button>}
          </Modal.Footer>)
      }
    </Modal>
  );
};

